import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { HeartOutline, HeartFilled } from '~components/Svg'
import { breakpoints } from '~styles/global'
import { useWishlist } from '~context/siteContext'

const WishlistButton = ({ className, productObject }) => {
  const { mobile } = breakpoints
  const { currentlyWishlisted, addRemoveWishlistItem } = useWishlist()
  return (
    <button
      onClick={() => addRemoveWishlistItem(productObject)}
      className={className}
      css={css`
        svg{
          width: 19px;
          ${mobile}{
            width: 11px;
          }
        }
      `}
    > 
    {currentlyWishlisted(productObject) ? <HeartFilled /> : <HeartOutline />}  
    </button>
  )
}

WishlistButton.propTypes = {
}

WishlistButton.defaultProps = {
}

export default WishlistButton
