import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import { Rarr } from '../../Svg';
import WishlistButton from '~components/blocks/WishlistButton'
import { Link } from "gatsby"


const ProductCard = ({ className, image, lifestyleImage, title, price, brand, link, cardDesc, slug, productId }) => {

  const {mobile, tablet} = breakpoints
  const [hovered, setHovered] = useState(false)

  const variantId = undefined

  // set the dafualt variant ID for the wishlist
  // if(product.purchasable && product.content.variants.length > 0){
  //   variantId = product.content.variants[0].shopifyVariantID
  // }

	// console.log(image?.asset)

  return (
    <div
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      css={css`display: block;`}
    >
      <Link to={link} css={css`
        margin-bottom: 34px;
        display: block;
        position: relative;
        ${mobile} {
          margin-bottom: 14px;
        }
      `}>
        <div css={css`
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          opacity: ${hovered && lifestyleImage ? `1`:`0`};
          pointer-events: ${hovered && lifestyleImage ? `auto`:`none`};
          z-index: 1;
          transition: opacity 0.3s;
          display: grid;
          grid-template-rows: max-content 1fr;
          ${mobile} {
            display: none;
          }
        `}>
          <div css={css`
            margin-bottom: 28px;
            ${tablet}{
              margin-bottom: 20px;
            }
          `}>
            <p css={css`
              text-align: center;
              margin-top: 10px;
              ${tablet}{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            `}>
              {cardDesc}
            </p>
          </div>
          <Image
            asset={lifestyleImage}
            aspectRatio={1.494}
            css={css`
            align-self: center;
          `}/>
        </div>
        <div css={css`
          opacity: ${hovered && lifestyleImage ? `0`:`1`};
          transition: opacity 0.3s;
          ${mobile} {
            opacity: 1;
          }
        `}>
          <Image
            asset={image}
            aspectRatio={1}
          />
        </div>
      </Link>
      <div css={css`
        display: grid;
        @media (max-width: 750px) {
          grid-template-columns: max-content min-content;
        }
      `}>
        <div css={css`
          margin-bottom: 20px;
          opacity: ${hovered ? `1`:`0`};
          transition: opacity 0.3s;
          pointer-events: ${hovered ? `auto`:`none`};
          display: grid;
          grid-template-columns: 1fr min-content;
          ${mobile} {
            grid-column: 2;
            grid-row: 1;
            opacity: 1;
          }
        `}>
          <div
            className="h6"
            css={css`
              ${mobile} {
                display: none;
              }
          `}>
            {price ? 
            <>
            <span css={css`
              margin-right: 64px;
            `}>from</span>
            ${price}
            </> : null
            }
          </div>
          <WishlistButton productObject={{
            variantId: undefined,
            productId: productId,
            title: title,
            productSlug: slug,
            price: price,
            brand: brand,
            options: [],
            image: image
          }}
          css={css`
            ${mobile} {
              padding-top: 2px
            }
          `}/>
        </div>
        <Link to={link} css={css`
          ${mobile} {
            grid-column: 1;
            grid-row: 1;
          }
        `}>
          <span
            className="h4"
            css={css`
              margin-right: 10px;
          `}>
            {title}
          </span>
          <span
            css={css`
              margin-right: 5px;
              ${mobile} {
                margin-right: 9px;
              }
          `}>
            {brand}
          </span>
          <Rarr css={css`
            opacity: ${hovered ? `1`:`0`};
            transition: opacity 0.3s;
            width: 10px;
            height: 10px;
            ${mobile} {
              display: none;
            }
          `}
          />
        </Link>
      </div>
    </div>
  )
}

ProductCard.propTypes = {
  title: PropTypes.string,
  brand: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  lifestyleImage: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  cardDesc: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

ProductCard.defaultProps = {
  // title: `Vele Bed`,
  // brand: `Lago`,
  // cardDesc: `Conceived as a dining room seat, it is also can be perfectly arranged as a the solo piece for smaller spaces....`,
  // price: `$4,500`,
  // link: `/`,
}

export default ProductCard
